import moment from 'moment';
import { reverseDateFormatSF } from '@lib/Utils';

export const isEligibleForPassportExpiredLogic = (appConfig, cardType, countryCode) => {
  if (appConfig && appConfig.acceptExpiredPassportUpto) {
    const eligibleCountries = Object.keys(appConfig.acceptExpiredPassportUpto);
    return cardType.match(/PASSPORT/i) && eligibleCountries.includes(countryCode);
  }
  return false;
};

export const isPassportExpiredBy2Years = (expiryDate, { appConfig, cardType, countryCode }) => {
  if (isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)) {
    const limitInYears = appConfig.acceptExpiredPassportUpto[countryCode];
    const enteredExpiry = moment(expiryDate, 'DD-MM-YYYY');
    const expiryDateLimit = moment(new Date()).subtract(limitInYears, 'years');
    return enteredExpiry.isBefore(expiryDateLimit, 'day');
  }

  return false;
};

export const isDocumentExpired = (expiryDate) => {
  const now = new Date().getTime();
  return now > reverseDateFormatSF(expiryDate, true).getTime();
};

export const isExpiredIdsAllowed = () => {
  const { ALLOW_EXPIRED_ID = false } = process.env;
  return ALLOW_EXPIRED_ID;
};
